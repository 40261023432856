export function getTypes(user){
    if (
        !user
        || !user.customData
        || !user.customData.profils
        || user.customData.profils.length === 0
    ) return 'NONE';

    if (user.customData.profils.includes('Admin')) return 'ALL';
    if (user.customData.profils.includes('SaisieLog')) return 'ALL';

    var productionTypes = []
    if (user.customData.profils.includes('SaisieALU')) productionTypes.push('ALU');
    if (user.customData.profils.includes('SaisieNEG')) productionTypes.push('NEG');
    if (user.customData.profils.includes('SaisiePVC')) productionTypes.push('PVC');
    if (user.customData.profils.includes('SaisieVR')) productionTypes.push('VR');

    return productionTypes;
}

export function checkRole(user, zone, role) {
    if (
        !user
        || !user.customData
        || !user.customData.profils
        || user.customData.profils.length === 0
    ) return false;

    if (user.customData.profils.includes('Admin')) return true;

    switch(zone) {
        case 'creation':
            return user.customData.profils.includes('CreationCommande');
        case 'date':
            return user.customData.profils.includes('CreationCommande')
                    || user.customData.profils.includes('SaisieLog')
        case 'statut':
            return user.customData.profils.includes('CreationCommande')
                || user.customData.profils.includes('SaisieLog')
        case 'produit':
            return user.customData.profils.includes('SaisieLog');
        case 'envoye':
            return user.customData.profils.includes('SaisieLog');
        case 'detail':
            return true;
        case 'modification':
            return user.customData.profils.includes('CreationCommande');
        case 'suppressionCommande':
            return user.customData.profils.includes('CreationCommande');
        case 'suppressionLigne':
            if(user.customData.profils.includes('SaisieLog')) return true;
            return user.customData.profils.includes('Saisie' + role);
        case 'commentaire':
            if(user.customData.profils.includes('SaisieLog')) return true;
            return user.customData.profils.includes('Saisie' + role);
        case 'qte':
            return user.customData.profils.includes('Saisie' + role);
        case 'raf':
            return user.customData.profils.includes('Saisie' + role);
        case 'zone':
            return user.customData.profils.includes('Saisie' + role);
        case 'valide':
            return user.customData.profils.includes('Saisie' + role);
        case 'finalise':
            if(zone === 'LOG' && user.customData.profils.includes('SaisieLog')) return true;
            return user.customData.profils.includes('Saisie' + role);
        default:
            return false;
    }

}

