export function isAnon(user) {
    return !user || user.identities[0].providerType === 'anon-user'
}

export const toggleBoolean = (prev) => !prev;

const isValidArrayIndex = (arr, idx) => {
    return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr, idx, value) {
    if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
        throw new Error(`Cannot add value. Array index out of bounds.`);
    }
    return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr, idx, newValue) {
    if (!isValidArrayIndex(arr, idx)) {
        throw new Error(`Cannot replace value. Array index out of bounds.`);
    }
    return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr, idx, updater) {
    if (!isValidArrayIndex(arr, idx)) {
        throw new Error(`Cannot update value. Array index out of bounds.`);
    }
    return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr, idx) {
    if (!isValidArrayIndex(arr, idx)) {
        throw new Error(`Cannot remove value. Array index out of bounds.`);
    }
    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const isSameObject = (obj1, obj2) =>
    String(obj1?._id) === String(obj2?._id);

export const getOrderIndex = (objs, obj) => {
    const idx = objs.findIndex((t) => isSameObject(t, obj));
    return idx >= 0 ? idx : null;
};
