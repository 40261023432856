export const ProductionType = new Map([
  ["ALU_Ouvrants_Coulissant", {"type":"01-ALU_Ouvrants_Coulissant",  "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Dormants_Coulissant", {"type":"02-ALU_Dormants_Coulissant",  "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Menuiseries",         {"type":"03-ALU_Menuiseries",          "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Ouvrants",            {"type":"04-ALU_Ouvrants",             "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Divers",              {"type":"06-ALU_Divers",               "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Dormants",            {"type":"05-ALU_Dormants",             "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Panneaux",            {"type":"07-ALU_Panneaux",             "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Vitrages",            {"type":"08-ALU_Vitrages",             "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Bottes_profils",      {"type":"09-ALU_Bottes_profils",       "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["ALU_Boites_accessoires",  {"type":"10-ALU_Boites_accessoires",   "productionType": "ALU",  "productionDateDayMinus": 2 }],
  ["PVC_Menuiseries",         {"type":"11-PVC_Menuiseries",          "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Dormants",            {"type":"12-PVC_Dormants",             "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Ouvrants",            {"type":"13-PVC_Ouvrants",             "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Divers",              {"type":"14-PVC_Divers",               "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Panneaux",            {"type":"15-PVC_Panneaux",             "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Vitrages",            {"type":"16-PVC_Vitrages",             "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Bottes_habillages",   {"type":"17-PVC_Bottes_habillages",    "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Bottes_cornieres",    {"type":"18-PVC_Bottes_cornieres",     "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["PVC_Boites_accessoires",  {"type":"19-PVC_Boites_accessoires",   "productionType": "PVC",  "productionDateDayMinus": 2 }],
  ["VR_Demi_Linteau",         {"type":"20-VR_Demi_Linteau",          "productionType": "VR",   "productionDateDayMinus": 3 }],
  ["VR_Coffre_monobloc",      {"type":"21-VR_Coffre_monobloc",       "productionType": "VR",   "productionDateDayMinus": 3 }],
  ["VR_Pose",                 {"type":"22-VR_Pose",                  "productionType": "VR",   "productionDateDayMinus": 3 }],
  ["NEG_Négoce",              {"type":"23-NEG_Négoce",               "productionType": "NEG",  "productionDateDayMinus": 2 }],
  ["NEG_Achats_speciaux",     {"type":"24-NEG_Achats_speciaux",      "productionType": "NEG",  "productionDateDayMinus": 2 }],
  ["BP_ALU",                  {"type":"25-BP_ALU",                   "productionType": "BP",   "productionDateDayMinus": 2 }],
  ["BP_PVC",                  {"type":"26-BP_PVC",                   "productionType": "BP",   "productionDateDayMinus": 2 }],
  ["BP_PVC_ACC",              {"type":"27-BP_PVC_ACC",               "productionType": "BP",   "productionDateDayMinus": 2 }],
  ["BP_EXPED",                {"type":"28-BP_EXPED",                 "productionType": "BP",   "productionDateDayMinus": 2 }],
])