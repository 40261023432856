import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@material-ui/core";
import { Colors } from "../config/js/Colors";

const themeConfig = {
  palette: {
    primary: Colors.green,
    secondary: Colors.green,
  },
}

const theme = createTheme(themeConfig);

export function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
