import React from "react";
import {Button, Nav, Navbar} from "react-bootstrap"

import {useRealmApp} from "../core/RealmApp";
import { isAnon } from "../core/Utils"

function Navigation () {
    const { currentUser, logOut, currentOrder, setCurrentOrder, setRefreshPending } = useRealmApp();
    const onInitialChange = (val) => currentUser.defaultInitials = val.target.value;
    const loggedIn = !isAnon(currentUser)

    if(loggedIn && !currentUser.defaultInitials) {
        if(currentUser.customData && currentUser.customData.initials && currentUser.customData.initials.length > 0) {
            currentUser.defaultInitials = currentUser.customData.initials[0]
        }
    }

    return (
        loggedIn ?
        <Navbar bg="light" expand="lg">
            <Navbar.Brand className="mx-2">Commandes et expéditions</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    &nbsp;&nbsp;&nbsp;
                    {currentOrder._id !==0 && <><Button onClick={async () => { await setCurrentOrder({_id: 0});}} className="btn-primary">Retour à la liste</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Button onClick={async () => {await setRefreshPending();}} className="btn-secondary">Rechargement</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {loggedIn && <><Button onClick={async () => {await logOut();}} className="btn-danger">Déconnexion</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                </Nav>
                    <form className="form-inline">
                        {currentUser.customData.name} / {currentUser.customData.role} :&nbsp;
                        <select id="initials" className="custom-select" onInput={onInitialChange}>
                            {currentUser.customData.initials.map((initialsItem) => (
                                <option key={initialsItem} className="col-4">{initialsItem}</option>
                            ))}
                        </select>
                    </form>
            </Navbar.Collapse>
        </Navbar>
        : <Navbar bg="light" expand="lg"></Navbar>
    )
}

export default Navigation