import React, { useState } from 'react'
import OrderCard from '../components/OrderCard'
import {Button, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat"
import { useShowLoader } from "../hooks/common/Util-hooks";
import { useOrders } from "../hooks/UseOrders";
import {LinearProgress} from "@material-ui/core";

import { i18n } from "dateformat";
import {ProductionType} from "../config/js/ProductionType";
import {useForm} from "react-hook-form";
import {useRealmApp} from "../core/RealmApp";

import {checkRole, getTypes} from "../core/FuncFilter"

i18n.dayNames = [
    "Dim",
    "Lun",
    "Mar",
    "Mer",
    "Jeu",
    "Ven",
    "Sam",
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
];

i18n.monthNames = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];

function ListOrdersPage () {
    const realmApp = useRealmApp();
    const { t } = useTranslation();
    const { loading, orders, ...orderActions } = useOrders();
    const { register, unregister, reset, handleSubmit } = useForm();
    const showLoader = useShowLoader(loading, 200);
    const [filterNumberTmp, setFilterNumberTmp] = React.useState("")
    const [showCreate, setShowCreate] = useState(false);
    const handleCloseCreate = () => setShowCreate(false);
    const handleShowCreate = () => setShowCreate(true)

    const handleShowCreateValues = (val) => {
        if(realmApp.currentOrder._id !== 0) {
            updateAllGeneric(val, "PVC")
            updateAllGeneric(val, null)
            unregister("number")
            unregister("deliveryDate")
        }
    }

    function onError(values) {
        console.log(values)
    }

    function onSubmit(values) {
        //console.log(values)
        createOrderOrLinesAS(values).finally(
    () => {
                reset({
                    number: "",
                    deliveryDate: ""
                })
                handleCloseCreate()
            }
        )
    }

    async function createOrderOrLinesAS(values) {
        if(realmApp.currentOrder._id !== 0) {
            await orderActions.createOrderLines(realmApp.currentOrder, values);
        } else {
            await orderActions.createOrder(values);
        }
    }

    function handleFilterNumberChange(val) {
        setFilterNumberTmp(val.target.value);
        if(val.target.value.length > 3) {
            orderActions.setFilterNumber(val.target.value);
        } else if(orderActions.filterNumber !== val.target.value){
            orderActions.setFilterNumber("");
        }
    }

    // can add e parameter if needed
    function updateAll(type) {
        updateAllGeneric(document, type)
    }

    function updateAllGeneric(where, type) {
        Array.from(ProductionType.keys()).forEach((key) => {
            if (type === null || type === ProductionType.get(key).productionType) {
                //console.log(key.toString())
                if (document.getElementsByName(key.toString()).length > 0) {
                    //console.log(document.getElementsByName(key.toString()))
                    document.getElementsByName(key.toString()).item(0).click()
                }
            }
        });
    }

    const handleSortChange = (val) => orderActions.setSort(val.target.value);
    const handleFilterStatutChange = (val) => orderActions.setFilterStatut(val.target.value);
    const handleFilterDateChange = (val) => orderActions.setFilterDate(val.target.value);
    const handleFilterTypeChange = (val) => orderActions.setFilterType(val.target.value);

    return (
        <>
            <Modal show={showCreate}  onEnter={handleShowCreateValues} onHide={handleCloseCreate} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    {
                        realmApp.currentOrder._id === 0 ? (
                            <Modal.Title>Nouvelle commande</Modal.Title>
                        ) : (
                            <Modal.Title>Modification commande<br/>Ajout de ligne</Modal.Title>
                        )
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit, onError)} >
                        <div>
                            {realmApp.currentOrder._id === 0 &&(<Form.Row className="align-items-center">
                                <Form.Label className="col-sm-6">Commande (Format : numéro + en facultatif le nom client)</Form.Label>
                                <Form.Control className="col-sm-6" type="text" name="number" placeholder="NUMCMD - CLIENT / NUMCMD.Fx - CLIENT / SAV.NUMSAV - NUMDOSSIER - DESCRIPTION / PDA.NUM - DESCRIPTION ou CLIENT" {...register("number", {required: true})} />
                            </Form.Row>)}
                            {realmApp.currentOrder._id === 0 &&(<Form.Row className="align-items-center">
                                <Form.Label className="col-sm-6">Date de livraison prévue (Format : JJ/MM/AAAA)</Form.Label>
                                <Form.Control className="col-sm-6" type="text" name="deliveryDate" placeholder="02/05/2022" {...register("deliveryDate", {required: true, pattern: /^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/i})} />
                            </Form.Row>)}
                            {realmApp.currentOrder._id === 0 &&(<br/>)}
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Row><Form.Label className="col-sm-8"><h3>Productions ALU</h3></Form.Label><Button style={{ fontSize: 32 }}  className="col-sm-1" name="ALL-ALU" onClick={(e) => updateAll("ALU", e)} >+</Button></Form.Row>
                                    {Array.from(ProductionType.keys()).map((key) => {
                                        let value = ProductionType.get(key)
                                        return value.productionType === "ALU" ? (<Form.Row><Form.Label className="col-sm-8">{t('order.types.' + value.type)}</Form.Label><Form.Control className="col-sm-1" type="checkbox" name={key} defaultChecked={true} {...register(key.toString(), {})}/></Form.Row>) : ""
                                    })}
                                </div>
                                <div className="col-sm-6">
                                    <Form.Row><Form.Label className="col-sm-8"><h3>Productions PVC</h3></Form.Label><Button style={{ fontSize: 32 }}  className="col-sm-1" name="ALL-PVC" onClick={(e) => updateAll("PVC", e)} >+</Button></Form.Row>
                                    {Array.from(ProductionType.keys()).map((key) => {
                                        let value = ProductionType.get(key)
                                        return value.productionType === "PVC" ? (<Form.Row><Form.Label className="col-sm-8">{t('order.types.' + value.type)}</Form.Label><Form.Control className="col-sm-1" type="checkbox" name={key} defaultChecked={realmApp.currentOrder._id === 0 && value.type === '14-PVC_Divers'} {...register(key.toString(), {})} /></Form.Row>) : ""
                                    })}
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Row><Form.Label className="col-sm-8"><h3>Productions VR</h3></Form.Label><Button style={{ fontSize: 32 }}  className="col-sm-1" name="ALL-VR" onClick={(e) => updateAll("VR", e)} >+</Button></Form.Row>
                                    {Array.from(ProductionType.keys()).map((key) => {
                                        let value = ProductionType.get(key)
                                        return value.productionType === "VR" ? (<Form.Row><Form.Label className="col-sm-8">{t('order.types.' + value.type)}</Form.Label><Form.Control className="col-sm-1" type="checkbox" name={key} defaultChecked={true} {...register(key.toString(), {})} /></Form.Row>) : ""
                                    })}
                                    <hr/>
                                    <Form.Row><Form.Label className="col-sm-8"><h3>Négoce</h3></Form.Label><Button style={{ fontSize: 32 }}  className="col-sm-1" name="ALL-NEG" onClick={(e) => updateAll("NEG", e)} >+</Button></Form.Row>
                                    {Array.from(ProductionType.keys()).map((key) => {
                                        let value = ProductionType.get(key)
                                        return value.productionType === "NEG" ? (<Form.Row><Form.Label className="col-sm-8">{t('order.types.' + value.type)}</Form.Label><Form.Control className="col-sm-1" type="checkbox" name={key.toString()} defaultChecked={true} {...register(key.toString(), {})} /></Form.Row>) : ""
                                    })}
                                </div>
                                <div className="col-sm-6">
                                    <Form.Row><Form.Label className="col-sm-8"><h3>Bon de prépa</h3></Form.Label><Button style={{ fontSize: 32 }}  className="col-sm-1" name="ALL-BP" onClick={(e) => updateAll("BP", e)} >+</Button></Form.Row>
                                    {Array.from(ProductionType.keys()).map((key) => {
                                        let value = ProductionType.get(key)
                                        return value.productionType === "BP" ? (<Form.Row><Form.Label className="col-sm-8">{t('order.types.' + value.type)}</Form.Label><Form.Control className="col-sm-1" type="checkbox" name={key.toString()} defaultChecked={true} {...register(key.toString(), {})} /></Form.Row>) : ""
                                    })}
                                </div>
                            </div>
                            <div className="text-center mt-2">
                                <Button variant="primary" type="submit">Valider</Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className="mt-3">
                {loading ? (
                    showLoader ? (
                        <LinearProgress />
                    ) : null
                ) : (
                    <div>
                        <div className="form-row">
                            <div className="col-2">
                                <label htmlFor="filterNumberField">Commande</label>
                                <input autoFocus id="filterNumberField" className="form-control" type="text" value={filterNumberTmp}
                                       onChange={handleFilterNumberChange} />
                            </div>
                            <div className="col-2">
                                <label htmlFor="filterDateField">Livraison prévue</label>
                                <select id="filterDateField" className="custom-select" value={orderActions.filterDate}
                                        onInput={handleFilterDateChange}>
                                    <option value="ALL">{t('order.date.ALL')}</option>
                                    {Array.from(Array(9), (e, i) => {
                                        let date = new Date();
                                        date.setDate(date.getDate() + i);
                                        if(date.getDay() === 0 || date.getDay() === 6 ) {
                                            return null
                                        }
                                        return <option key={i} value={date.toLocaleDateString("fr")}>{dateFormat(date, "dddd dd/mm")}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-2">
                                <label htmlFor="filterTypeField">Type</label>
                                <select id="filterTypeField" className="custom-select" value={orderActions.filterType}
                                        onInput={handleFilterTypeChange}>
                                    {getTypes(realmApp.currentUser) !== 'ALL' && getTypes(realmApp.currentUser) !== 'NONE' && <option value={getTypes(realmApp.currentUser)}>Perso : {getTypes(realmApp.currentUser).join("/")}</option>}
                                    <option value="ALL">{t('order.type.ALL')}</option>
                                    <option value="VR">{t('order.type.VR')}</option>
                                    <option value="PVC">{t('order.type.PVC')}</option>
                                    <option value="PVC-EC">{t('order.type.PVC-EC')}</option>
                                    <option value="ALU">{t('order.type.ALU')}</option>
                                    <option value="ALU-EC">{t('order.type.ALU-EC')}</option>
                                    <option value="NEG">{t('order.type.NEG')}</option>
                                    <option value="BP">{t('order.type.BP')}</option>
                                </select>
                            </div>
                            <div className="col-2">
                                <label htmlFor="filterStatutField">Statut</label>
                                <select id="filterStatutField" className="custom-select" value={orderActions.filterStatut}
                                        onInput={handleFilterStatutChange}>
                                    <option className={"style-ALL"} value="ALL">{t('order.status.ALL')}</option>
                                    <option className={"style-PLAN"} value="PLAN">{t('order.status.PLAN')}</option>
                                    <option className={"style-PLAN-LATE"}
                                            value="PLAN-LATE">{t('order.status.PLAN-LATE')}</option>
                                    <option className={"style-PARTIAL"}
                                            value="PARTIAL">{t('order.status.PARTIAL')}</option>
                                    <option className={"style-PARTIAL-LATE"}
                                            value="PARTIAL-LATE">{t('order.status.PARTIAL-LATE')}</option>
                                    <option className={"style-DONE"} value="DONE">{t('order.status.DONE')}</option>
                                    <option className={"style-PREPARED"} value="PREPARED">{t('order.status.PREPARED')}</option>
                                    <option className={"style-SENT"} value="SENT">{t('order.status.SENT')}</option>
                                    <option className={"style-ARCHIVE"} value="ARCHIVE">{t('order.status.ARCHIVE')}</option>
                                </select>
                            </div>
                            <div className="col-2">
                                <label htmlFor="sortField">Trier par</label>
                                <select id="sortField" className="custom-select" value={orderActions.sort} onInput={handleSortChange}>
                                    <option value="_id:1">Commande &uarr;</option>
                                    <option value="_id:-1">Commande &darr;</option>
                                    <option value="deliveryDate:1">Date &uarr;</option>
                                    <option value="deliveryDate:-1">Date &darr;</option>
                                </select>
                            </div>
                            <div className="col-2">
                                {(realmApp.currentOrder._id === 0)  ? (
                                    checkRole(realmApp.currentUser, 'creation', null) && <Button id="createOrder" variant="primary float-right" onClick={handleShowCreate}>Créer une<br/>commande</Button>
                                ) : (
                                    checkRole(realmApp.currentUser, 'modification', null) && <Button id="updateOrder" variant="primary float-right" onClick={handleShowCreate}>Modifier une<br/>commande</Button>
                                )}
                            </div>
                            <br/>
                        </div>
                        {(realmApp.currentOrder._id === 0)  ? (
                            <div className="row">
                                    <div className="col-3">Numéro de commande</div>
                                    <div className="col-3">Date de départ<br/>Date production limite</div>
                                    <div className="col-4">Statut<br/>Avancement</div>
                                    <div className="col-2"/>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-3">&nbsp;</div>
                                <div className="col-3">&nbsp;</div>
                                <div className="col-4">&nbsp;</div>
                                <div className="col-2"/>
                            </div>
                        )}
                        {orders.filter(order => (realmApp.currentOrder._id === 0 || order._id === realmApp.currentOrder._id)).map((order) => (
                            <OrderCard key={order._id} order={order} detail={realmApp.currentOrder._id !== 0 && orders.length === 1} orderActions={orderActions} />
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default ListOrdersPage