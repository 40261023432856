import React from "react"
import {BrowserRouter} from "react-router-dom"

import Navigation from "../components/Navigation"
import {ThemeProvider} from "./Theme";
import {RealmAppProvider, useRealmApp} from "./RealmApp";

import ListOrdersPage from "../pages/ListOrdersPage"
import AuthenticationPage from "../pages/AuthenticationPage";

import conf from "../config/realm.json";

import 'bootstrap/dist/css/bootstrap.min.css'
import "./App.css";

export default function AppWithRealm() {
  return (
      <ThemeProvider>
        <RealmAppProvider appId={conf.appId}>
          <App />
        </RealmAppProvider>
      </ThemeProvider>
  );
}

function App() {
  const { currentUser } = useRealmApp();

    return (
    <BrowserRouter>
      <Navigation user={currentUser} />
      {currentUser ?
          <ListOrdersPage />
          :
          <AuthenticationPage/>
      }
    </BrowserRouter>
  );
}
