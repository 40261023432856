import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import {Button, Form} from "react-bootstrap";
import Card from 'react-bootstrap/Card'

import {GiFactory} from "react-icons/gi";
import {FiTruck} from "react-icons/fi";
import {FiTrash2} from "react-icons/fi";
import {TiZoom} from "react-icons/ti";

import {Formik} from "formik";
import Loading from "./Loading";
import {useRealmApp} from "../core/RealmApp";

import {checkRole} from "../core/FuncFilter"

function OrderCard ({order, detail, orderActions}) {
    const realmApp = useRealmApp();
    const { register } = useForm();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)

    let updates = useState([])
    let needSave = useState([])

    // ========================= Async methods =========================
    async function cleanProductionTypeAS(productionType) {
        let parts = document.querySelectorAll('[name^="type-"]')
        let partsToDelete = []
        for (const part of parts) {
            const partProductionType = part.getAttribute('name').split("-")[1]
            const partId = part.getAttribute('name').split("-")[2]
            if(productionType === partProductionType) {
                let comment = document.getElementsByName("comment-" + partId)[0].value
                let nbFab = document.getElementsByName("nbFab-" + partId)[0].value
                let rafFab = document.getElementsByName("rafFab-" + partId)[0].value
                let zoneFab = document.getElementsByName("zoneFab-" + partId)[0].value
                //let completeFab = document.getElementsByName("completeFab-" + partId)[0].checked
                let nbLog = document.getElementsByName("nbLog-" + partId)[0].value
                let rafLog = document.getElementsByName("rafLog-" + partId)[0].value
                let zoneLog = document.getElementsByName("zoneLog-" + partId)[0].value
                //let completeLog = document.getElementsByName("completeLog-" + partId)[0].checked
                if((comment === '' || comment.startsWith('INIT AUTOMATIQUE')) && nbFab === '0' && rafFab === '0' && zoneFab === '' && nbLog === '0' && rafLog === '0' && zoneLog === '') {
                    partsToDelete.push(partId)
                }
            }
        }
        for (const partId of partsToDelete) {
            await orderActions.deletePart(order, parseInt(partId))
        }
    }

    async function updatePartAS(val) {
        const partId = parseInt(val.target.name.split("-")[1], 10)
        let input = false
        if(updates["zoneFab-" + partId]) {
            //console("update zoneFab")
            await orderActions.updateZoneFab(order, updates["zoneFab-" + partId], partId);
            updates["zoneFab-" + partId] = null
            input = true
        }
        if(updates["zoneLog-" + partId]) {
            //console("update zoneLog")
            await orderActions.updateZoneLog(order, updates["zoneLog-" + partId], partId);
            updates["zoneLog-" + partId] = null
            input = true
        }
        if(updates["completeFab-" + partId] !== undefined && updates["completeFab-" + partId] !== null) {
            //console("update completeFab")
            await orderActions.updateCompleteFab(order, updates["completeFab-" + partId], partId);
            updates["completeFab-" + partId] = null
            input = true
        }
        if(updates["completeLog-" + partId] !== undefined && updates["completeLog-" + partId] !== null) {
            //console("update completeLog")
            await orderActions.updateCompleteLog(order, updates["completeLog-" + partId], partId);
            updates["completeLog-" + partId] = null
            input = true
        }
        if(updates["comment-" + partId]) {
            //console("update comment")
            await orderActions.updateComment(order, updates["comment-" + partId], partId);
            updates["comment-" + partId] = null
            input = true
        }
        if(updates["nbLog-" + partId]) {
            //console("update nbLog")
            await orderActions.updateNbLog(order, updates["nbLog-" + partId], partId);
            updates["nbLog-" + partId] = null
            input = true
        }
        if(updates["nbFab-" + partId]) {
            //console("update nbFab")
            await orderActions.updateNbFab(order, updates["nbFab-" + partId], partId);
            updates["nbFab-" + partId] = null
            input = true
        }
        if(updates["rafLog-" + partId]) {
            //console("update rafLog")
            await orderActions.updateRafLog(order, updates["rafLog-" + partId], partId);
            updates["rafLog-" + partId] = null
            input = true
        }
        if(updates["rafFab-" + partId]) {
            //console("update rafFab")
            await orderActions.updateRafFab(order, updates["rafFab-" + partId], partId);
            updates["rafFab-" + partId] = null
            input = true
        }
        needSave["part-" + parseInt(val.target.name.split("-")[1], 10)] = false;
        document.getElementsByName("valide-" + partId)[0].style.display = 'none'
        if(input) {
            if(order.statut === "PLAN") {
                await updateStatut("PARTIAL")
            } else if(order.statut === "PLAN-LATE") {
                await updateStatut("PARTIAL-LATE")
            }
        }
        await checkComplete(order)
    }

    async function updateStatutAS(val) {
        await orderActions.updateStatut(order, val);
    }

    async function updateDeliveryDateAS(val) {
        await orderActions.updateDeliveryDate(order, val);
    }

    async function deleteOrderAS () {
        await orderActions.deleteOrder(order);
    }

    async function deletePartAS(partId) {
        await orderActions.deletePart(order, partId);
    }
    // ========================= END Async methods =========================

    function handleSubmit() {
    }

    function openDetail() {
        realmApp.setCurrentOrder(order)
    }

    function cleanProductionType(val) {
        //console.log("debut")
        let productionType = val.target.name.split("-")[1]
        if( window.confirm("Attention, êtes vous sur de supprimer toutes les lignes " + productionType + " vides ?")) {
            setLoading(true)
            cleanProductionTypeAS(productionType).then().finally(
                () => {
                    //console.log("fini")
                    realmApp.setRefreshPending();
                    setLoading(false)
                }
            )
        }
    }

    function updatePart(val) {
        //console("debut")
        setLoading(true)
        updatePartAS(val).then().finally(
            () => {
                //console("fini")
                realmApp.setRefreshPending();
                setLoading(false)
            }
        )
    }

    function updateComment(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["comment-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "")

    }

    function activeElements(partId, type) {
        if(needSave["part-" + partId]) {
            document.getElementsByName("valide-" + partId)[0].style.display = 'inline-block'
        }
        if(type !== '') {
            let nb = document.getElementsByName("nb" + type +  "-" + partId)[0].value
            let raf = document.getElementsByName("raf" + type +  "-" + partId)[0].value
            let zone = document.getElementsByName("zone" + type +  "-" + partId)[0].value
            let complete = document.getElementsByName("complete" + type +  "-" + partId)[0]

            if (nb === '0' || raf !== '0' || zone === '') {
                complete.disabled = true
                complete.checked = false
                updates["complete" + type + "-" + partId] = false;
            } else {
                complete.disabled = false
            }
        }
    }

    async function checkComplete(order) {
        let allFabComplete = true
        let allLogComplete = true
        document.querySelectorAll('[name^="completeFab-"]').forEach(
            (el) => !el.checked ? allFabComplete = false : null
        )
        document.querySelectorAll('[name^="completeLog-"]').forEach(
            (el) => !el.checked ? allLogComplete = false : null
        )
        if(allLogComplete) {
            await orderActions.updateStatut(order, "PREPARED")
        } else if(allFabComplete) {
            await orderActions.updateStatut(order, "DONE")
        } else {
            await orderActions.updateStatut(order, "PARTIAL")
        }
   }

    function updateNbFab(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["nbFab-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Fab")
    }

    function updateRafFab(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["rafFab-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Fab")
    }

    function updateZoneFab(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["zoneFab-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Fab")
    }

    function updateCompleteFab(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["completeFab-" + partId] = val.target.checked;
        needSave["part-" + partId] = true;
        activeElements(partId, "Fab")
    }


    function updateNbLog(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["nbLog-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Log")
    }

    function updateRafLog(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["rafLog-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Log")
    }

    function updateZoneLog(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["zoneLog-" + partId] = val.target.value;
        needSave["part-" + partId] = true;
        activeElements(partId, "Log")
    }

    function updateCompleteLog(val) {
        let partId = parseInt(val.target.name.split("-")[1], 10)
        updates["completeLog-" + partId] = val.target.checked;
        needSave["part-" + partId] = true;
        activeElements(partId, "Log")
    }

    function updateStatut(val) {
        //console.log("debut")
        setLoading(true)
        updateStatutAS(val).then().finally(
            () => {
                //console.log("fini")
                realmApp.setRefreshPending();
                setLoading(false)
            }
        )
    }

    function updateDeliveryDate(val) {
        //console.log("debut")
        setLoading(true)
        updateDeliveryDateAS(val).then().finally(
            () => {
                //console.log("fini")
                realmApp.setRefreshPending();
                setLoading(false)
            }
        )
    }

    function startProduction () {
        updateStatut(order, "PARTIAL");
    }

    function sendOrder () {
        updateStatut(order, "SENT");
    }

    function deleteOrder () {
        if( window.confirm("Attention, êtes vous sur de supprimer la commande en cours ?")) {
            //console.log("debut")
            setLoading(true)
            deleteOrderAS().then().finally(
                () => {
                    //console.log("fini")
                    realmApp.setCurrentOrder({ _id:0 })
                    setLoading(false)
                }
            )
        }
    }

    function deletePart(partId) {
        if( window.confirm("Attention, êtes vous sur de supprimer la ligne en cours ?")) {
            console.log("debut")
            setLoading(true)
            deletePartAS(partId).then().finally(
                () => {
                    console.log("fini")
                    realmApp.setRefreshPending();
                    setLoading(false)
                }
            )
        }
    }

    return (
        <Card className={"m-0 p-2 style-" + order.statut} >
            <Card.Title>
                <div className="row">
                    <div className="col-3">
                        {order._id}<br/>
                        {order.nbVR>0 && (<i><small>dossier avec VR</small></i>)}
                    </div>
                    {!detail && (<div className="col-3">
                        <input size="12" type="text" name="deliveryDate" disabled={!checkRole(realmApp.currentUser, 'date', null)} onBlur={updateDeliveryDate} defaultValue={order.deliveryDate !== undefined ? order.deliveryDate.toLocaleDateString("fr") : ''}/>
                        <br/><i>{order.firstProductionDate !== undefined ? order.firstProductionDate.toLocaleDateString("fr") : ''}</i>
                    </div>)}
                    {detail && (<div className="col-3">{order.deliveryDate !== undefined ? order.deliveryDate.toLocaleDateString("fr") : ''}<br/><i>{order.firstProductionDate !== undefined ? order.firstProductionDate.toLocaleDateString("fr") : ''}</i></div>)}
                    {!detail && (<div className="col-4">
                        <select id="sortField" className="custom-select" value={order.statut} disabled={!checkRole(realmApp.currentUser, 'statut', null)} onInput={updateStatut}>
                            <option className={"style-PLAN"} value="PLAN">{t('order.status.PLAN')}</option>
                            <option className={"style-PLAN-LATE"} value="PLAN-LATE">{t('order.status.PLAN-LATE')}</option>
                            <option className={"style-PARTIAL"} value="PARTIAL">{t('order.status.PARTIAL')}</option>
                            <option className={"style-PARTIAL-LATE"} value="PARTIAL-LATE">{t('order.status.PARTIAL-LATE')}</option>
                            <option className={"style-DONE"} value="DONE">{t('order.status.DONE')}</option>
                            <option className={"style-PREPARED"} value="PREPARED">{t('order.status.PREPARED')}</option>
                            <option className={"style-SENT"} value="SENT">{t('order.status.SENT')}</option>
                            <option className={"style-ARCHIVE"} value="ARCHIVE">{t('order.status.ARCHIVE')}</option>
                        </select>
                        <br/>
                        PVC : {order.nbCompletePVC}/{order.nbPVC}&nbsp;&nbsp;-&nbsp;&nbsp;ALU : {order.nbCompleteALU}/{order.nbALU}&nbsp;&nbsp;-&nbsp;&nbsp;VR : {order.nbCompleteVR}/{order.nbVR}<br/>NEG : {order.nbCompleteNEG}/{order.nbNEG}&nbsp;&nbsp;-&nbsp;&nbsp;BP : {order.nbCompleteBP}/{order.nbBP}
                    </div>)}
                    {detail && (<div className="col-4">{t('order.status.' + order.statut)}</div>)}
                    <div className="col-2">
                        {!detail && (<div className="row align-items-center">
                            {checkRole(realmApp.currentUser, 'produit', null) &&
                                <GiFactory className="blackIcon mx-2 float-right" onClick={startProduction}/>}
                            {checkRole(realmApp.currentUser, 'envoye', null) &&
                                <FiTruck className="blackIcon mx-2 float-right" onClick={sendOrder}/>}
                            {checkRole(realmApp.currentUser, 'detail', null) &&
                                <TiZoom className="blackIcon mx-2 float-right" onClick={openDetail}/>}
                        </div>)}
                        {detail && checkRole(realmApp.currentUser, 'suppressionCommande', null) && <FiTrash2 className="redIcon mx-2 float-right" onClick={deleteOrder}/>}
                    </div>
                </div>
            </Card.Title>
            {detail && (
                <Card.Body>
                    <div className="row" style={{fontWeight: "bold"}}>
                        <div className="col-2"/>
                        <div className="col-5">Type</div>
                        <div className="col-5"><span style={{display: "inline-block", width: "60px"}}>Qté</span><span style={{display: "inline-block", width: "60px"}}>RAF</span><span style={{display: "inline-block", width: "60px"}}>Zone</span><span style={{display: "inline-block", width: "70px"}}></span>Fini</div>
                    </div>
                    <Formik initialValues={[]} onSubmit={handleSubmit}>
                        <Form noValidate>
                            {loading && <Loading />}
                            {!loading && (<div>
                                {
                                    order.parts.sort((a, b) => (a.type+";"+a._id) < (b.type+";"+b._id) ? -1 : 1 ).map((part, idx, array) => (
                                    <div className="row" style={{marginBottom: "12px"}} id={"row-" + part._id}>
                                        <div className="col-2">
                                            {(idx >0 && array[idx].productionType !== array[idx-1].productionType) && <hr/>}
                                            <br/>{checkRole(realmApp.currentUser, 'suppressionLigne', array[idx].productionType) &&
                                            <FiTrash2 className="redIcon mx-2 float-right"
                                                      onClick={() => deletePart(part._id)}/>}
                                        </div>
                                        <div className="col-5">
                                            {(idx >0 && array[idx].productionType !== array[idx-1].productionType) && <hr/>}
                                            <span name={"type-" + part.productionType + "-" + part._id}>{t('order.types.' + part.type)}</span><br/><i>Date limite : {part.productionDate.toLocaleDateString("fr")}</i><br/><input type="text" placeholder="Commentaire / Fournisseur" size="35" defaultValue={part.comment} name={"comment-" + part._id} disabled={!checkRole(realmApp.currentUser, 'commentaire', array[idx].productionType)} onChange={updateComment}/>
                                        </div>
                                        <div className="col-5">
                                            {(idx >0 && array[idx].productionType !== array[idx-1].productionType) && <hr/>}
                                            <input type="text" size="2" defaultValue={part.nbFab} name={"nbFab-" + part._id} {...register("nbFab-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'qte', array[idx].productionType)} onChange={updateNbFab}/>
                                            <input type="text" size="2" defaultValue={part.rafFab} name={"rafFab-" + part._id} {...register("rafFab-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'raf', array[idx].productionType)} onChange={updateRafFab}/>
                                            <input type="text" size="4" defaultValue={part.zoneFab} name={"zoneFab-" + part._id} {...register("zoneFab-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'zone', array[idx].productionType)} onChange={updateZoneFab}/>
                                            <input type="text" size="2" defaultValue={part.signFab} disabled name={"signFab-" + part._id}/>
                                            &nbsp;<input style={{verticalAlign: "middle",width: "25px", height: "25px"}} type="checkbox" name={"completeFab-" + part._id} defaultChecked={part.completeFab} {...register("completeFab-" + part._id, {})} disabled={part.nbFab === 0 || part.rafFab !== 0 || part.zoneFab === '' || !checkRole(realmApp.currentUser, 'qte', array[idx].productionType)} onChange={updateCompleteFab} />
                                            &nbsp;&nbsp;&nbsp;<Button name={"valide-" + part._id} onClick={updatePart} style={{display: 'none' }}>Valide</Button>
                                            <br/>
                                            <input type="text" size="2" defaultValue={part.nbLog} name={"nbLog-" + part._id} {...register("nbLog-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'qte', 'Log')} onChange={updateNbLog}/>
                                            <input type="text" size="2" defaultValue={part.rafLog} name={"rafLog-" + part._id} {...register("rafLog-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'raf', 'Log')} onChange={updateRafLog}/>
                                            <input type="text" size="4" defaultValue={part.zoneLog} name={"zoneLog-" + part._id} {...register("zoneLog-" + part._id, {})} disabled={!checkRole(realmApp.currentUser, 'zone', 'Log')} onChange={updateZoneLog}/>
                                            <input type="text" size="2" defaultValue={part.signLog} disabled name={"signLog-" + part._id}/>
                                            &nbsp;<input style={{verticalAlign: "middle",width: "25px", height: "25px"}} type="checkbox" name={"completeLog-" + part._id} defaultChecked={part.completeLog} {...register("completeLog-" + part._id, {})} disabled={part.nbLog === 0 || part.rafLog !== 0 || part.zoneLog === '' || !checkRole(realmApp.currentUser, 'qte', 'Log') } onChange={updateCompleteLog} />
                                            &nbsp;&nbsp;&nbsp;{(idx === array.length - 1 || array[idx].productionType !== array[idx+1].productionType) && checkRole(realmApp.currentUser, 'finalise', array[idx].productionType) && <Button name={"clean-" + part.productionType} onClick={cleanProductionType}>Finalise {part.productionType}</Button>}
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>)}
                        </Form>
                    </Formik>
                </Card.Body>
            )}
        </Card>
    )
}

export default OrderCard