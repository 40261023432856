import React from "react";
import { useCollection } from "./common/UseCollection";
import { useRealmApp } from "../core/RealmApp";
import conf from "../config/realm.json";
import {ProductionType} from "../config/js/ProductionType";

export function useOrders() {
    // Set up a list of orders in state
    const realmApp = useRealmApp();

    const [orders, setOrders] = React.useState([]);
    const [sort, setSort] = React.useState("deliveryDate:1")
    const [filterNumber, setFilterNumber] = React.useState("")
    const [filterStatut, setFilterStatut] = React.useState("ALL")
    const [filterDate, setFilterDate] = React.useState("ALL")
    const [filterType, setFilterType] = React.useState( "ALL")

    // Get a client object for the order collection
    const orderCollection = useCollection({
        cluster: conf.dataSourceName,
        db: "expeditions",
        collection: "commandes",
    });

    // Fetch all orders on load and whenever our collection changes (e.g. if the current user changes)
    React.useEffect(() => {
        let pipeline

        let filterNumberClause = []
        let filterStatutClause = []
        let filterDateClause = []
        let filterTypeClause = []
        let $match
        let $matchPostAgg

        if(realmApp.currentOrder._id === 0) {
            if(filterNumber != null && filterNumber.length >= 3) {
                filterNumberClause = [{_id: new RegExp('^.*'+filterNumber+'.*', 'i')}]
            }
        } else {
            filterNumberClause = [{_id: realmApp.currentOrder._id}]
        }

        if(filterStatut === "ALL") {
            filterStatutClause = [{statut: {$ne: 'ARCHIVE'}}]
        }
        if(filterStatut != null && filterStatut !== "ALL") {
            filterStatutClause = [{statut: filterStatut}]
        }

        if(filterDate != null && filterDate !== "ALL") {
            let dateParts = filterDate.split("/")
            console.log(dateParts)
            let deliveryDate = new Date(Date.UTC(+dateParts[2], dateParts[1] - 1, +dateParts[0]))
            filterDateClause = [{deliveryDate: deliveryDate}]
        }

        if(filterType != null && filterType !== "ALL") {
            if(filterType.endsWith("-EC")) {
                if(filterType.startsWith("PVC-")) {
                    filterTypeClause = [{"parts.productionType": "PVC"}]
                    $matchPostAgg = { $expr: { $ne:[ '$nbPVC',  '$nbCompletePVC' ] } }
                }
                if(filterType.startsWith("ALU-")) {
                    filterTypeClause = [{"parts.productionType": "ALU"}]
                    $matchPostAgg = { $expr: { $ne:[ '$nbALU',  '$nbCompleteALU' ] } }
                }
            } else {
                if(filterType.indexOf(',')>0) {
                    filterTypeClause = [{"parts.productionType": {$in: filterType.split(',')}}]
                } else {
                    filterTypeClause = [{"parts.productionType": filterType}]
                }
                $matchPostAgg = { }
            }
        } else {
            $matchPostAgg = { }
        }

        let concatClause = filterNumberClause.concat(filterStatutClause).concat(filterDateClause).concat(filterDateClause).concat(filterTypeClause)
        if(concatClause != null && concatClause.length >= 1) {
            $match = {$and: concatClause}
        } else {
            $match = {}
        }

        let $sort
        switch (sort) {
            case "_id:1":
                $sort = {_id:1}
                break;
            case "_id:-1":
                $sort = {_id:-1}
                break;
            case "deliveryDate:1":
                $sort = {deliveryDate:1, _id:1}
                break;
            case "deliveryDate:-1":
                $sort = {deliveryDate:-1, _id:1}
                break;
            default:
                $sort = {deliveryDate:1, _id:1}
                break;
        }

        pipeline = [
            {$match},
            {$unwind: "$parts"},
            {$group: {
                    _id: "$_id",
                    firstProductionDateTS: { "$min": "$parts.productionDate"},
                    statut: {$first: "$statut"},
                    deliveryDate: {$first: "$deliveryDate"},
                    parts: {$push: "$parts"}
                }},
            {$addFields: {
                    firstProductionDate: {$toDate: "$firstProductionDateTS"},
                    nbPVC: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "PVC" ]} ] }
                            }
                        }
                    },
                    nbCompletePVC: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "PVC" ]}, {"$eq": [ "$$this.completeFab", true ]} ] }
                            }
                        }
                    },
                    nbVR: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "VR" ]} ] }
                            }
                        }
                    },
                    nbCompleteVR: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "VR" ]}, {"$eq": [ "$$this.completeFab", true ]} ] }
                            }
                        }
                    },
                    nbNEG: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "NEG" ]} ] }
                            }
                        }
                    },
                    nbCompleteNEG: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "NEG" ]}, {"$eq": [ "$$this.completeFab", true ]} ] }
                            }
                        }
                    },
                    nbBP: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "BP" ]} ] }
                            }
                        }
                    },
                    nbCompleteBP: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "BP" ]}, {"$eq": [ "$$this.completeFab", true ]} ] }
                            }
                        }
                    },
                    nbALU: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "ALU" ]} ] }
                            }
                        }
                    },
                    nbCompleteALU: {
                        "$size": {
                            "$filter": {
                                "input": "$parts",
                                "cond": { "$and": [ {"$eq": [ "$$this.productionType", "ALU" ]}, {"$eq": [ "$$this.completeFab", true ]} ] }
                            }
                        }
                    }
                }},
            {$match: $matchPostAgg},
            {$sort},
            {$limit: 50}
        ]

        orderCollection.aggregate(pipeline).then((fetchedOrders) => {
            setOrders(Array.from(fetchedOrders.values()));
        });

    }, [orderCollection, sort, filterNumber, filterStatut, filterType, filterDate, realmApp.currentOrder, realmApp.refreshPending]);

    // Given a draft order, format it and then insert it
    const createOrder = async (valuesObj) => {
        let values = Object.entries(valuesObj);
        const dateParts = valuesObj.deliveryDate.split("/")
        const deliveryDate = new Date(Date.UTC(+dateParts[2], dateParts[1] - 1, +dateParts[0]))
        const order = {
            "_id": valuesObj.number,
            "deliveryDate": deliveryDate,
            "statut": 'PLAN',
            "parts": []
        }
        let i = 0;
        for(let j=0; j<values.length; j++) {
            let currentValue = values[j];
            let key = currentValue[0];
            let value = currentValue[1]
            if(value === true && ProductionType.get(key) !== undefined) {
                //console.log(ProductionType.get(key).type)
                //console.log(ProductionType.get(key).productionType)
                order.parts.push(
                    {
                        "_id": i++,
                        "type": ProductionType.get(key).type,
                        "productionType": ProductionType.get(key).productionType,
                        "productionDate": new Date(deliveryDate - ProductionType.get(key).productionDateDayMinus * 24 * 60 * 60 * 1000),
                        "comment": "",
                        "nbFab": 0,
                        "rafFab": 0,
                        "signFab": "",
                        "zoneFab": "",
                        "completeFab": false,
                        "nbLog": 0,
                        "rafLog": 0,
                        "signLog": "",
                        "zoneLog": "",
                        "completeLog": false
                    }
                )
            }
        }
        await orderCollection.insertOne(order)
        await realmApp.setCurrentOrder(order)
    };

    const createOrderLines = async (order, valuesObj) => {
        let values = Object.entries(valuesObj);

        let maxPartId = Math.max(...order.parts.map(o => o._id))

        let i = maxPartId + 1;
        for(let j=0; j<values.length; j++) {
            let currentValue = values[j];
            let key = currentValue[0];
            let value = currentValue[1]
            if(value === true && ProductionType.get(key) !== undefined) {
                await orderCollection.updateOne({"_id": order._id}, {$push: {parts:
                    {
                        "_id": i++,
                        "type": ProductionType.get(key).type,
                        "productionType": ProductionType.get(key).productionType,
                        "productionDate": new Date(order.deliveryDate - ProductionType.get(key).productionDateDayMinus * 24 * 60 * 60 * 1000),
                        "comment": "",
                        "nbFab": 0,
                        "rafFab": 0,
                        "signFab": "",
                        "zoneFab": "",
                        "completeFab": false,
                        "nbLog": 0,
                        "rafLog": 0,
                        "signLog": "",
                        "zoneLog": "",
                        "completeLog": false
                    }
                }})
            }
        }
    };

    const updateOrder = async (order) => {
        await orderCollection.findOneAndReplace({"_id": order._id}, order)
    };

    const updateComment = async (order, val, partId) => {
        if(order.comment === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.comment": val}})
    }

    const updateZoneFab = async (order, val, partId) => {
        if(order.zoneFab === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.zoneFab": val,
                    "parts.$.signFab": realmApp.currentUser.defaultInitials}})
    }

    const updateZoneLog = async (order, val, partId) => {
        if(order.zoneLog === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.zoneLog": val,
                    "parts.$.signLog": realmApp.currentUser.defaultInitials}})
    }

    const updateCompleteFab = async (order, val, partId) => {
        if(order.completeFab === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.completeFab": val,
                    "parts.$.signFab": realmApp.currentUser.defaultInitials}})
    }

    const updateCompleteLog = async (order, val, partId) => {
        if(order.completeLog === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.completeLog": val,
                    "parts.$.signLog": realmApp.currentUser.defaultInitials}})
    }

    const updateNbLog = async (order, val, partId) => {
        if(order.nbLog === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.nbLog": parseInt(val, 10),
                    "parts.$.signLog": realmApp.currentUser.defaultInitials}})
    }

    const updateNbFab = async (order, val, partId) => {
        if(order.nbFab === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.nbFab": parseInt(val, 10),
                    "parts.$.signFab": realmApp.currentUser.defaultInitials}})
    }

    const updateRafLog = async (order, val, partId) => {
        if(order.rafLog === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.rafLog": parseInt(val, 10),
                    "parts.$.signLog": realmApp.currentUser.defaultInitials}})
    }

    const updateRafFab = async (order, val, partId) => {
        if(order.rafFab === val) return
        await orderCollection.updateOne({"_id": order._id, "parts._id": partId},
            {$set: {"parts.$.rafFab": parseInt(val, 10),
                    "parts.$.signFab": realmApp.currentUser.defaultInitials}})
    }

    const updateStatut = async (order, val) => {
        if(order.statut === val) return
        let value
        if(val.target) {
            value = val.target.value
        } else {
            value = val
        }
        await orderCollection.updateOne({"_id": order._id}, {$set: {"statut": value}})
    }

    const updateDeliveryDate = async (order, val) => {
        //console.log("update deliveryDate")
        const value = val.target.value
        //console.log(value)
        const dateParts = value.split("/")
        const deliveryDate = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, +dateParts[0]))

        order.deliveryDate = deliveryDate
        await orderCollection.updateMany(
            {
                "_id": order._id
            },{
                $set: {
                    "deliveryDate": deliveryDate,
                    "parts.$[no].productionDate": new Date(deliveryDate -  2 * 24 * 60 * 60 * 1000),
                    "parts.$[vr].productionDate": new Date(deliveryDate - 3 * 24 * 60 * 60 * 1000)
                }
            }, {
                arrayFilters: [
                    {"no.productionType": {$ne: "VR"}},
                    {"vr.productionType": {$eq: "VR"}}
                ]
            })
    }

    const deleteOrder = async (order) => {
        await orderCollection.deleteOne({"_id": order._id})
    }

    const deletePart = async (order, partId) => {
        await orderCollection.updateOne({"_id": order._id}, {$pull: {parts: {_id: partId}}})
    }

    return {
        orders: orders,
        createOrder: createOrder,
        createOrderLines: createOrderLines,
        updateOrder: updateOrder,
        deleteOrder: deleteOrder,
        updateComment: updateComment,
        updateZoneFab: updateZoneFab,
        updateZoneLog: updateZoneLog,
        updateCompleteFab: updateCompleteFab,
        updateCompleteLog: updateCompleteLog,
        updateNbLog: updateNbLog,
        updateRafLog: updateRafLog,
        updateNbFab: updateNbFab,
        updateRafFab: updateRafFab,
        updateStatut: updateStatut,
        updateDeliveryDate: updateDeliveryDate,
        deletePart: deletePart,
        sort: sort,
        filterNumber: filterNumber,
        filterStatut: filterStatut,
        filterDate: filterDate,
        filterType: filterType,
        setSort: setSort,
        setFilterNumber: setFilterNumber,
        setFilterStatut: setFilterStatut,
        setFilterDate: setFilterDate,
        setFilterType: setFilterType
    };
}
